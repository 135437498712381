import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import { Link, withRouter } from "react-router-dom";
import SVG from "react-inlinesvg";
import { setPhoneNumber } from "../../../../redux/actions/phone-number-actions";
import { getInputClassName, toastMessage, updateCurrentUser } from "../../../helpers";
import api from "../../../../redux/api";
import ConfirmModal from "../../modals/ConfirmModal";
import { useFormik } from "formik";
import schemas from "../../../schemas";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import TagsInput from "react-tagsinput";

const StepOneNumber = 1,
  StepConnectTelnyxNumber = 2,
  StepConnectFacebookNumber = 3,
  StepConfirmationCodeNumber = 4,
  StepConfigureNumberNumber = 5,
  StepCompletedNumber = 6,
  StepAlreadyConnectedNumber = 7;

function StepOne({ setStep, phoneNumberSource, setPhoneNumberSource, show }) {
  let [check1checked, setCheck1checked] = useState(),
    [check2checked, setCheck2checked] = useState();

  return (
    <div className={"card main-card " + (show ? "" : "d-none")}>
      <div className="card-body">
        <h1 className="mb-6">WhatsApp Number</h1>
        <p className="mb-12">Please provide a phone number that is not already connected to a WhatsApp mobile or desktop app. You can use your own number, a verified Facebook number or a Telnyx number. Need help, please check out <a href="#">Support Page</a>.</p>

        <button type="button" className={"option " + (phoneNumberSource == "sim" ? "active" : "")} onClick={() => setPhoneNumberSource("sim")}>
          <SVG src="/media/def-image/icons/phone-lg.svg" />
          <div>
            <label>I have a phone number</label>

            <label className="checkbox checkbox-outline mt-3">
              <input type="checkbox" onChange={ev => setCheck1checked(ev.target.checked)} checked={check1checked} />
              <span />
              This number is not currently being used in the WhatsApp mobile or desktop app.
            </label>

            <label className="checkbox checkbox-outline mt-2">
              <input type="checkbox" onChange={ev => setCheck2checked(ev.target.checked)} checked={check2checked} />
              <span />
              I can receive a confirmation text with this number
            </label>
          </div>
        </button>

        <button type="button" className={"option " + (phoneNumberSource == "existing" ? "active" : "")} onClick={() => setPhoneNumberSource("existing")}>
          <SVG src="/media/def-image/icons/facebook-verified.svg" />
          <div>
            <label>I have a verified phone number with my Facebook Business account</label>
            <p>Connect your existing verified Facebook Business number to Hiro.</p>
          </div>
        </button>

        <button type="button" className={"option " + (phoneNumberSource == "telnyx" ? "active" : "")} onClick={() => setPhoneNumberSource("telnyx")}>
          <SVG src="/media/def-image/icons/telnyx.svg" />
          <div>
            <label>Use a Telnyx number</label>
            <p>Connect a Telnyx number to use for WhatsApp messages.</p>
          </div>
        </button>
      </div>

      <div className="card-footer border-0 text-right">
        <button type="button" className="btn btn-primary" onClick={() => setStep(phoneNumberSource == "sim" || phoneNumberSource == "existing" ? StepConnectFacebookNumber : StepConnectTelnyxNumber)} disabled={!phoneNumberSource || (phoneNumberSource == "sim" && (!check1checked || !check2checked))}>Next Step</button>
      </div>
    </div>
  );
}

function StepConnectTelnyx({ setStep, user, telnyxPhoneNumber, setTelnyxPhoneNumber, show, updateUser }) {
  const [loading, setLoading] = useState(true),
    [numbers, setNumbers] = useState([]),
    [saving, setSaving] = useState();

  async function loadData() {
    const res = await api.user.getUserTelnyxData();

    setLoading(false);

    // if(!res || !res.success)
    //   return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setNumbers(res.data.existingNumbers);
  }

  async function nextOnClick() {
    setSaving(true);

    const res = await api.user.setUserTelnyxPhoneNumberForWhatsapp(telnyxPhoneNumber.id);

    setSaving(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    updateUser();

    setSaving(false);

    setStep(StepConnectFacebookNumber);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={"card main-card " + (show ? "" : "d-none")}>
      <div className="card-body">
        <h1 className="mb-6">Connect Number</h1>

        {user.telnyxConnected
          ? (
            <>
              <p className="mb-12">Select the Telnyx phone number you want to use for WhatsApp. Need help, please check out <a href="#">Support Page</a>.</p>

              {loading
                ? <div className="loading-block spinner" />
                : (
                  <div className={numbers.length > 15 ? "two-columns-md" : ""}>
                    {numbers.length
                      ? numbers.map(number => (
                        <label className="form-check mb-4" key={number.id}>
                          <input className="form-check-input" type="radio" name="number" onChange={ev => setTelnyxPhoneNumber(number)} />
                          {number.formatted}
                          {number.currentForSms && <span className="badge gray-badge ml-2">Current for SMS</span>}
                        </label>
                      ))
                      : (
                        <div className="text-muted">You don’t have any phone numbers in your Telnyx account.</div>
                      )}
                  </div>
                )}
            </>
          )
          : (
            <>
              <p>You have to connect your Telnyx account first.</p>
            </>
          )}
      </div>
      <div className="card-footer border-0 d-flex justify-content-between">
        {user.telnyxConnected
          ? (
            <>
              <button type="button" className="btn btn-gray" onClick={() => setStep(StepOneNumber)}>Back</button>
              <button type="button" className={"btn btn-primary " + (saving ? "loading spinner" : "")} onClick={nextOnClick} disabled={!telnyxPhoneNumber.id}>Next Step</button>
            </>
          )
          : (
            <>
              <button type="button" className="btn btn-gray" onClick={() => setStep(StepOneNumber)}>Back</button>
              <Link to="/user/phone-numbers" className="btn btn-primary">Go to Phone Settings</Link>
            </>
          )}
      </div>
    </div>
  );
}

function StepConnectFacebook({ setStep, phoneNumberSource, telnyxPhoneNumber, show, setFacebookLoginData }) {
  let [loaded, setLoaded] = useState();

  window.fbAsyncInit = function() {
    window.FB.init({
      appId: '2143309712690466',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v19.0'
    });
    setLoaded(true);
  };

  function launchWhatsappSignup() {
    if(phoneNumberSource == "telnyx")
      setStep(StepConfirmationCodeNumber);

    window.FB?.login(function(response) {
      if(response && response.status == "connected" && response.authResponse && response.authResponse.code) {
        setFacebookLoginData(response.authResponse);
        setStep(StepConfigureNumberNumber);
      } else {
        toastMessage.error("Facebook login was cancelled!");
        setStep(StepConnectFacebookNumber);
      }
    }, {
      config_id: "1155717725772140",
      response_type: "code",
      override_default_response_type: true,
      extras: {
        setup: {}
      }
    });
  }

  useEffect(() => {
    if(typeof window.FB != "undefined") {
      setLoaded(true);
    } else {
      let script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      document.head.appendChild(script);
    }
  }, []);

  return (
    <div className={"card main-card " + (show ? "" : "d-none")}>
      <div className="card-body">
        <h1 className="mb-6">Connect Facebook</h1>

        {phoneNumberSource == "telnyx"
          ? (
            <>
              <p className="mb-6">Click the button below to connect your Facebook account. Follow the steps to configure your account, add your phone number or select existing number. Need help, please check out <a href="#">Support Page</a>.</p>
              <p className="mb-12">
                <strong>Very important!</strong> When asked, enter the phone number <strong>{telnyxPhoneNumber.formatted}</strong> and come back to this page to get your confirmation code.
              </p>
            </>
          )
          : (
            <p className="mb-12">Click the button below to connect your Facebook account. Follow the steps to configure your account and add your phone number. Need help, please check out <a href="#">Support Page</a>.</p>
          )}

        <button type="button" onClick={() => launchWhatsappSignup()} disabled={!loaded} className="btn btn-primary btn-facebook-blue d-inline-flex gap align-items-center">
          <SVG src="/media/def-image/icons/facebook-circle.svg" className="w-20px" />
          Continue with Facebook
        </button>
      </div>

      <div className="card-footer border-0">
        <button type="button" className="btn btn-gray" onClick={() => setStep(phoneNumberSource == "telnyx" ? StepConnectTelnyxNumber : StepOneNumber)}>Back</button>
      </div>
    </div>
  );
}

function StepConfirmationCode({ setStep, show, telnyxPhoneNumber }) {
  let pollTimerRef = useRef(),
    [code, setCode] = useState();

  async function load() {
    let res = await api.user.getWhatsappConfirmationCode();

    if(res.success && res.code)
      setCode(res.code);
    else
      startPolling();
  }

  function startPolling() {
    //setCode(null);
    pollTimerRef.current = setTimeout(() => load(), 1000);
  }

  useEffect(() => {
    if(show)
      startPolling();

    return () => {
      clearTimeout(pollTimerRef.current);
    };
  }, [show]);

  return (
    <div className={"card main-card " + (show ? "" : "d-none")}>
      <div className="card-body">
        <h1 className="mb-6">Confirmation Code</h1>
        <p className="mb-12">In the Facebook popup, when asked, enter the phone number <strong>{telnyxPhoneNumber.formatted}</strong>, and then enter the following
          confirmation code. Need help, please check out <a href="#">Support Page</a>.</p>

        {code
          ? (
            <div className="confirmation-code">
              <label className="form-label">Confirmation Code</label>
              <big>{code}</big>
            </div>
          )
          : <div className="loading-block spinner" />}
      </div>

      <div className="card-footer border-0 d-flex justify-content-between">
        <button type="button" className="btn btn-gray" onClick={() => setStep(StepConnectFacebookNumber)}>Back</button>
        <button type="button" className="btn btn-primary" onClick={() => setStep(StepConfigureNumberNumber)} disabled={!code}>Complete</button>
      </div>
    </div>
  );
}

function StepConfigureNumber({ show, setStep, facebookLoginData, user, updateUser, telnyxPhoneNumber }) {
  let [loading, setLoading] = useState(),
    [error, setError] = useState(),
    [confirmNumberList, setConfirmNumberList] = useState([]),
    [selectedNumber, setSelectedNumber] = useState();

  async function submit() {
    setLoading(true);
    setConfirmNumberList([]);

    let res = await api.user.setupWhatsappPhoneNumber({
      ...facebookLoginData,
      selectedNumber
    });

    setLoading(false);

    if(res?.confirmNumber) {
      setConfirmNumberList(res.confirmNumber);
      setSelectedNumber(res.confirmNumber[0]);
      return;
    }

    await updateUser();

    if(!res || !res.success) {
      if(res.error)
        toastMessage.error(res.error);
      setError(true);
    } else {
      setStep(StepAlreadyConnectedNumber);
    }
  }

  useEffect(() => {
    setSelectedNumber(telnyxPhoneNumber
      ? telnyxPhoneNumber.formatted
      : null
    );
  }, [telnyxPhoneNumber]);

  useEffect(() => {
    if(show && facebookLoginData)
      submit();
  }, [show, facebookLoginData]);

  return (
    <div className={"card main-card " + (show ? "" : "d-none")}>
      <div className="card-body">
        {confirmNumberList.length > 0
          ? (
            <>
              <h1 className="mb-6">Confirm your WhatsApp number</h1>

              <p className="mb-10">We found multiple numbers in your Facebook Business account. Please confirm which one you choose in the previous step.</p>

              {confirmNumberList.map(n => (
                <label className="form-check mb-4" key={n}>
                  <input className="form-check-input" type="radio" name="number" onChange={ev => setSelectedNumber(n)} checked={selectedNumber == n} />
                  {n}
                </label>
              ))}
            </>
          )
          : (
            <>
              {loading && (
                <>
                  <h1 className="mb-6">Almost done</h1>
                  <p>
                    <span className="loading spinner mr-12" />
                    One more minute, we‘re finishing setting up your account.
                  </p>
                </>
              )}

              {error && (
                <>
                  <h1 className="mb-6">Oh no...</h1>
                  <p>Something went wrong. Please, try again later.</p>
                </>
              )}

              {!loading && !error && (
                <>
                  <h1 className="mb-6">Almost done</h1>
                  <p>Complete the steps in the Facebook popup to continue...</p>
                </>
              )}
            </>
          )}
      </div>

      {confirmNumberList.length > 0 && (
        <div className="card-footer border-0 d-flex justify-content-between">
          <button type="button" className="btn btn-gray" onClick={() => setStep(StepConnectFacebookNumber)}>Back</button>
          <button type="button" className="btn btn-primary" onClick={submit}>Continue</button>
        </div>
      )}
    </div>
  );
}

function StepCompleted({ user, dispatch, show, setStep, updateUser }) {
  let [whatsappNumber, setWhatsappNumber] = useState(),
    [showConfirmDisconnect, setShowConfirmDisconnect] = useState(),
    [disconnectLoading, setDisconnectLoading] = useState();

  async function confirmDisconnect() {
    setDisconnectLoading(true);

    await api.user.disconnectWhatsappPhoneNumber();

    await updateUser();

    toastMessage.success("WhatsApp number disconnected.");

    setStep(StepOneNumber);
    setDisconnectLoading(false);
    setShowConfirmDisconnect(false);
  }

  useEffect(() => {
    let number = user.phoneNumbers.find(phoneNumber => phoneNumber.kind == "whatsapp");
    setWhatsappNumber(number
      ? number.number
      : "");
  }, [user]);

  return (
    <>
      <div className={"card main-card " + (show ? "" : "d-none")}>
        <div className="card-body">
          {user && user.whatsappIsConnected && (
            <>
              <h1 className="mb-6">Your Are Done!</h1>

              <p className="mb-10">You can now create keywords and send messages using WhatsApp.</p>

              <div className="d-flex gap mb-10">
                <SVG src="/media/def-image/icons/whatsapp.svg" />
                <strong>WhatsApp number</strong>
                {whatsappNumber}
              </div>

              <div className="alert alert-warning d-flex align-items-center flex-row mb-20">
                <SVG src="/media/svg/icons/Code/Warning-2.svg" className="svg-icon" />
                <div>
                  <h4>Contacts must message you first!</h4>
                  To send WhatsApp messages, your contact must message your first.
                </div>
              </div>

              <button type="button" className="btn btn-primary" onClick={() => setShowConfirmDisconnect(true)}>Disconnect</button>
            </>
          )}
        </div>
      </div>

      <ConfirmModal
        show={showConfirmDisconnect}
        message="Are you sure you want to disconnect your WhatsApp number?"
        onConfirm={confirmDisconnect}
        onCancel={() => setShowConfirmDisconnect(false)}
        loading={disconnectLoading}
      />
    </>
  );
}

function Whatsapp({ user, phoneNumber, dispatch }) {
  let [step, setStep] = useState(1),
    [phoneNumberSource, setPhoneNumberSource] = useState(""),
    [telnyxPhoneNumber, setTelnyxPhoneNumber] = useState({}),
    [facebookLoginData, setFacebookLoginData] = useState(),
    [hasPendingWhatsapp, setHasPendingWhatsapp] = useState(),
    [currentPhoneNumber, setCurrentPhonNumber] = useState();

  async function removePhoneNumberOnClick(ev) {
    ev.preventDefault();

    await api.user.disconnectWhatsappPhoneNumber();

    await updateCurrentUser(dispatch);

    toastMessage.success("WhatsApp number disconnected.");
  }

  useEffect(() => {
    if(!user)
      return;

    if(user.whatsappIsConnected && step == 1)
      setStep(StepAlreadyConnectedNumber);

    let currentWhatsapp = user.phoneNumbers.find(p => p.kind == "whatsapp");
    if(currentWhatsapp) {
      setHasPendingWhatsapp(step == 1 && currentWhatsapp.pending);
      setCurrentPhonNumber(currentWhatsapp.number);
    } else {
      setHasPendingWhatsapp(false);
      setCurrentPhonNumber(null);
    }
  }, [user]);

  return (
    <>
      <ProfileNavigation active="whatsapp" />

      {hasPendingWhatsapp && (
        <div className="alert mb-10 alert-warning d-flex align-items-center m-0">
          <SVG src="/media/svg/icons/Code/Warning-2.svg" className="svg-icon" />
          <div>
            <h4>You started WhatsApp number {currentPhoneNumber} and it‘s not verified.</h4>
            To use this number start the process from step 1 again. To use a different number <a href="#" className="underline" onClick={ev => removePhoneNumberOnClick(ev)}>click here to cancel {currentPhoneNumber}</a>.
          </div>
        </div>
      )}

      <div className="whatsapp-setup-form">
        <div className="card steps-card">
          <div className="card-body">
            <div className={"step " + (step == 1 ? "active" : "")}>
              <div className="number">
                {step == 1
                  ? "1"
                  : <SVG src="/media/def-image/icons/check-blue-lg.svg" />}
              </div>
              <label>WhatsApp Number</label>
            </div>

            <div className={"step " + (step == 2 ? "active" : "")}>
              <div className="number">
                {step <= 2
                  ? "2"
                  : <SVG src="/media/def-image/icons/check-blue-lg.svg" />}
              </div>
              <label>Connect Number</label>
            </div>

            <div className={"step " + (step == 3 ? "active" : "")}>
              <div className="number">
                {step <= 3
                  ? "3"
                  : <SVG src="/media/def-image/icons/check-blue-lg.svg" />}
              </div>
              <label>Connect to Facebook</label>
            </div>

            <div className={"step " + (step == 4 ? "active" : "")}>
              <div className="number">
                {step <= 4
                  ? "4"
                  : <SVG src="/media/def-image/icons/check-blue-lg.svg" />}
              </div>
              <label>Confirmation Code</label>
            </div>

            <div className={"step " + (step == 5 ? "active" : "")}>
              <div className="number">
                {step <= 5
                  ? "5"
                  : <SVG src="/media/def-image/icons/check-blue-lg.svg" />}
              </div>
              <label>Configure Number</label>
            </div>

            <div className={"step " + (step > 6 ? "done" : "")}>
              <div className="number">
                {step <= 6
                  ? "6"
                  : <SVG src="/media/def-image/icons/check-blue-lg.svg" />}
              </div>
              <label>Completed</label>
            </div>
          </div>
        </div>

        <StepOne show={step == StepOneNumber} setStep={setStep} phoneNumberSource={phoneNumberSource} setPhoneNumberSource={setPhoneNumberSource} />

        <StepConnectTelnyx show={step == StepConnectTelnyxNumber} setStep={setStep} user={user} telnyxPhoneNumber={telnyxPhoneNumber} setTelnyxPhoneNumber={setTelnyxPhoneNumber} updateUser={() => updateCurrentUser(dispatch)} />

        <StepConnectFacebook show={step == StepConnectFacebookNumber} setStep={setStep} user={user} phoneNumberSource={phoneNumberSource} telnyxPhoneNumber={telnyxPhoneNumber} facebookLoginData={facebookLoginData} setFacebookLoginData={setFacebookLoginData} />

        <StepConfirmationCode show={step == StepConfirmationCodeNumber} setStep={setStep} user={user} telnyxPhoneNumber={telnyxPhoneNumber} />

        <StepConfigureNumber telnyxPhoneNumber={telnyxPhoneNumber} show={step == StepConfigureNumberNumber} setStep={setStep} user={user} facebookLoginData={facebookLoginData} updateUser={() => updateCurrentUser(dispatch)} />

        <StepCompleted dispatch={dispatch} show={step >= StepCompletedNumber} setStep={setStep} user={user} updateUser={() => updateCurrentUser(dispatch)} />
      </div>

      {user && user.whatsappIsConnected && <OptOut user={user} dispatch={dispatch} />}
    </>
  );
}

function OptOut({ user, dispatch }) {
  const formik = useFormik({
    initialValues: {
      whatsappOptOutWords: user.whatsappOptOutWords || [],
      whatsappOptOutMessage: user.whatsappOptOutMessage || "",
      whatsappResubscribeMessage: user.whatsappResubscribeMessage || "",
      whatsappResubscribeWords: user.whatsappResubscribeWords || []
    },
    validationSchema: schemas.user.user.whatsappOptOutSettings,
    enableReinitialize: true,
    onSubmit: sendForm
  });

  const { handleSubmit, resetForm, setFieldValue, getFieldProps, dirty, values, touched, errors, isValid } = formik,
    [isSaving, setIsSaving] = useState(false);

  async function sendForm(values, loading = true) {
    setIsSaving(loading);

    let res = await api.user.editCurrentUser({
      action: "updateWhatsappOptOut",
      user: values
    });

    setIsSaving(false);

    if(!res)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Settings updated!");

    await updateCurrentUser(dispatch);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <div>
              <h3 className="card-title fw-bold m-0">WhatsApp Opt-out Management</h3>
              <p className="text-muted">Set additional opt-out words and reply messages.</p>
            </div>
          </div>
          <div className="card-body pt-9 pb-9">
            <div className="form-group">
              <label className="form-label">
                Additional Opt-out words
                <small>Predefined keywords: cancel, end, quit, unsubscribe, stop, stopall</small>
              </label>
              <TagsInput addOnBlur className={getInputClassName(formik, "whatsappOptOutWords", "react-tagsinput")} value={values.whatsappOptOutWords} onChange={v => setFieldValue("whatsappOptOutWords", v)} inputProps={{ placeholder: "" }} />
              {touched.whatsappOptOutWords && errors.whatsappOptOutWords && <div className="field-error">{errors.whatsappOptOutWords}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Opt-out Message
                <small>This will be sent immediately after the first SMS we send to the contact.</small></label>
              <textarea className={getInputClassName(formik, "whatsappOptOutMessage")} {...getFieldProps("whatsappOptOutMessage")} placeholder="Reply STOP to desuscribe from all our notifications." />
              {touched.whatsappOptOutMessage && errors.whatsappOptOutMessage && <div className="field-error">{errors.whatsappOptOutMessage}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Resubscribe Message
                <small>This will be sent after the contact sends the opt-out word.</small></label>
              <textarea className={getInputClassName(formik, "whatsappResubscribeMessage")} {...getFieldProps("whatsappResubscribeMessage")} placeholder="Sorry to se you go. Reply START to resubscribe." />
              {touched.whatsappResubscribeMessage && errors.whatsappResubscribeMessage && <div className="field-error">{errors.whatsappResubscribeMessage}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">
                Resubscribe words
                <small>Predefined keywords: resubscribe, start</small>
              </label>
              <TagsInput addOnBlur className={getInputClassName(formik, "whatsappResubscribeWords", "react-tagsinput")} value={values.whatsappResubscribeWords} onChange={v => setFieldValue("whatsappResubscribeWords", v)} inputProps={{ placeholder: "" }} />
              {touched.whatsappResubscribeWords && errors.whatsappResubscribeWords && <div className="field-error">{errors.whatsappResubscribeWords}</div>}
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="button" className="btn btn-secondary mr-2" disabled={!dirty} onClick={() => formik.resetForm()}>Discard</button>
            <button type="submit" className={"btn btn-primary " + (isSaving === true ? "loading spinner" : "")} disabled={!(isValid && dirty)}>Save Changes</button>
          </div>
        </div>
      </form>

      <UnsavedFormGuard formik={formik} onSaveAsync={async () => sendForm(formik.values, "modal")} loading={isSaving == "modal"} />
    </>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
    phoneNumber: state.phoneNumber.current
  }),
  (dispatch) => ({
    ...actions.auth,
    ...actions.phoneNumber,
    dispatch
  })
)(withRouter(Whatsapp));